import {
  ArrowLeftIcon,
  ArrowRightIcon,
  GlobeAltIcon,
  HeartIcon,
  LightBulbIcon,
  LightningBoltIcon,
} from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PageWrapper from "./PageWrapper";

const BANNER_PNG = require("../assets/img/logos/png/Banner.png");
const BANNER_WEBP = require("../assets/img/logos/webp/Banner.webp");
const LOGO_INVERT_PNG = require("../assets/img/logos/png/Logo_invert.png");
const LOGO_INVERT_WEBP = require("../assets/img/logos/webp/Logo_invert.webp");
const IGP_LOGO_PNG = require("../assets/img/logos/png/IGP.png");
const IGP_LOGO_WEBP = require("../assets/img/logos/webp/IGP.webp");
const ZBW_PNG = require("../assets/img/logos/png/zumBluemewageli.png");
const ZBW_WEBP = require("../assets/img/logos/webp/zumBluemewageli.webp");

const Home = () => {
  const { t } = useTranslation();

  const [item, setItem] = useState(0);
  const projects = [
    {
      name: "Impact Genome Project",
      link: "https://impactgenome.org",
      year: "Ongoing",
      customer: "Mission Metrics LLC",
      technologies: ["NodeJS", "React", "MySQL", "Python"],
      description: "ig_project",
    },
    {
      name: "Tracking Project",
      link: null,
      year: "2022",
      customer: "Intern",
      technologies: ["React", "MySQL", "PHP"],
      description: "tracking_project",
    },
    {
      name: "Smart Home",
      link: "https://waremama.ch",
      year: "2021",
      customer: "WaReMaMa",
      technologies: ["Python", "MySQL"],
      description: "smarthome_project",
    },
    {
      name: "corona.waremama.ch",
      link: "https://corona.waremama.ch/#/map",
      customer: "Intern",
      year: "2020",
      technologies: ["React", "D3", "Python"],
      description: "corona_project",
    },
    {
      name: "remoroethlisberger.ch",
      link: "http://remoroethlisberger.ch",
      year: "2018",
      customer: "Remo Röthlisberger",
      technologies: ["Wordpress", "PHP", "jQuery"],
      description: "remo_project",
    },
  ];

  useEffect(() => {
    const keyPressed = (e) => {
      if (e.key === "ArrowRight") {
        if (item < projects.length - 1) {
          setItem(item + 1);
        }
      }
      if (e.key === "ArrowLeft") {
        if (item > 0) {
          setItem(item - 1);
        }
      }
    };
    document.addEventListener("keydown", keyPressed);
    return () => {
      document.removeEventListener("keydown", keyPressed);
    };
  }, [item, projects.length]);

  return (
    <PageWrapper title={"Home"}>
      <div className="">
        <div className="relative">
          <div className="h-200 sm:h-400">
            <picture>
              <source srcSet={BANNER_WEBP} />
              <img
                className="z-10 opacity-60 w-full"
                alt="Background"
                src={BANNER_PNG}
              />
            </picture>
          </div>

          <Link to={"/"}>
            <div className="absolute flex space-x-4 top-1/4 right-8 text-3xl font-bold text-white md:text-5xl">
              Swiss Tech <br />
              Solutions GmbH
              <div className="w-16 h-16 md:w-24 md:h-24">
                <picture>
                  <source srcSet={LOGO_INVERT_WEBP} />
                  <img
                    className="w-16 h-16 md:w-24 md:h-24"
                    alt="Logo"
                    src={LOGO_INVERT_PNG}
                  />
                </picture>
              </div>
            </div>
            {/* <div className="absolute flex top-1/4 right-16 text-2xl font-bold text-white sm:text-3xl">
              Swiss Tech <br />
              Solutions GmbH
              <img className="w-20" src={LOGO_INVERT} />
            </div> */}
          </Link>
        </div>
        <div className="z-40 -mt-6 xs:-mt-8 sm:-mt-24 lg:-mt-36 w-full space-y-4">
          <div className="grid grid-cols-1 gap-y-4 md:grid-cols-2 md:gap-y-2 md:gap-x-3 lg:grid-cols-4 lg:gap-x-4 lg:gap-y-0 lg:p-4 md:p-2">
            <div className="z-40 bg-gray-400 h-42 lg:h-42 w-11/12 md:w-full mx-auto p-3 rounded-2xl">
              <div className="grid grid-cols-4">
                <div className="col-span-3 text-xl font-medium text-green-800">
                  {t("Connected")}
                </div>
                <div className="flex justify-end">
                  <GlobeAltIcon
                    className="h-8 w-8 text-green-800"
                    aria-hidden="true"
                  />
                </div>
              </div>
              {t("tagline_connected")}
            </div>
            <div className="z-40 bg-gray-400 h-42 lg:h-42 w-11/12 md:w-full mx-auto p-3 rounded-2xl">
              <div className="grid grid-cols-4">
                <div className="col-span-3 text-xl font-medium text-yellow-300">
                  {t("Ideas")}
                </div>
                <div className="flex justify-end">
                  <LightBulbIcon
                    className="h-8 w-8 text-yellow-300"
                    aria-hidden="true"
                  />
                </div>
              </div>
              {t("tagline_ideas")}
            </div>
            <div className="z-40 bg-gray-400 h-42 lg:h-42 w-11/12 md:w-full mx-auto p-3 rounded-2xl">
              <div className="grid grid-cols-4">
                <div className="col-span-3 text-xl font-medium">
                  {t("Speed")}
                </div>
                <div className="flex justify-end">
                  <LightningBoltIcon
                    className="h-8 w-8"
                    aria-hidden="true"
                    color="white"
                  />
                </div>
              </div>
              <div className="my-auto">{t("tagline_speed")}</div>
            </div>
            <div className="z-40 bg-gray-400 h-42 lg:h-42 w-11/12 md:w-full mx-auto p-3 rounded-2xl">
              <div className="grid grid-cols-4">
                <div className="col-span-3 text-red-600 text-xl font-medium">
                  {t("Local Business")}
                </div>
                <div className="flex justify-end">
                  <HeartIcon
                    className="w-8 h-8 text-red-600"
                    fill="red"
                    aria-hidden="true"
                  />
                </div>
              </div>
              {t("tagline_local")}
            </div>
          </div>
          <div className="px-4 md:px-2 lg:px-4  pt-4 border-t">
            <div className="bg-gray-300 h-full p-4 text-black rounded-2xl">
              <span className="text-xl font-semibold">
                Swiss Tech Solutions GmbH:
              </span>
              <br />
              {t("welcome_paragraph")}
            </div>
          </div>
          {/* <div className="relative p-4">
              <div className="absolute text-black p-4"> adahsdas</div>
              <div className="grid grid-cols-2 gap-x-4 opacity-50">
                <img src={BANNER} alt="Waaa" /> 
                <img src={BANNER} alt="Waaa" />
              </div>
            </div> */}

          <div className="px-4 md:px-2 lg:px-4 pt-4 border-t">
            <div className="bg-gray-300 h-full p-4 text-black rounded-2xl">
              <span className="text-xl font-semibold">
                {t("Services and Core Competence")}:
              </span>
              <br />
              {t("services_paragraph")}
              <br />
              <br />
              <span className="font-medium">{t("Core Competences")}:</span>
              <div className="pl-6">
                <ul style={{ listStyleType: "disc" }}>
                  <li>{t("service_item_1")}</li>
                  <li>{t("service_item_2")}</li>
                  <li>{t("service_item_3")}</li>
                  <li>{t("service_item_4")}</li>
                  <li>{t("service_item_5")}</li>
                  <li>{t("service_item_6")}</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="px-4 md:px-2 lg:px-4 pt-4 border-t">
            <div className="bg-gray-300 h-full p-4 text-black rounded-2xl">
              <div className="w-full md:text-left mb-2">
                <span className="text-xl font-semibold">{t("Customers")}:</span>
              </div>
              <div className="grid grid-cols-1 gap-y-2 md:grid-cols-2 md:gap-x-8">
                <div className="mx-auto md:border border-black border-none rounded-2xl p-2">
                  <a
                    href="https://impactgenome.org"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <picture>
                      <source srcSet={IGP_LOGO_WEBP} />
                      <img
                        className="object-scale-down h-48 w-96"
                        alt="Impact Genome Registery"
                        src={IGP_LOGO_PNG}
                      />
                    </picture>
                  </a>
                </div>
                <div className="mx-auto md:border border-black border-none rounded-2xl p-2">
                  <a
                    href="https://bluemewägeli.ch"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <picture>
                      <source srcSet={ZBW_WEBP} />
                      <img
                        className="object-scale-down h-48 w-96"
                        alt="Zum Bluemewägeli"
                        src={ZBW_PNG}
                      />
                    </picture>
                  </a>
                </div>
                {/* <div className="mx-auto md:border border-black rounded-2xl p-2">
                  <img
                    className="object-scale-down h-48 w-96"
                    alt="Impact Genome Registery"
                    src={ZBW}
                  />
                </div> */}
              </div>
            </div>
          </div>

          <div className="px-4 md:px-2 lg:px-4 pt-4 border-t">
            <div className="bg-gray-300 h-full p-4 text-black rounded-2xl">
              <span className="text-xl font-semibold">{t("References")}</span>
              <br />
              <div className="pt-4">
                {projects.map((p, i) => {
                  return (
                    <div
                      className={`w-full ${i === item ? "block" : "hidden"}`}
                    >
                      <div className="grid grid-cols-1 md:grid-cols-8 justify-between items-center">
                        <div className="hidden md:flex justify-center">
                          {i !== 0 && (
                            <ArrowLeftIcon
                              onClick={() => {
                                setItem((prev) => {
                                  return prev - 1;
                                });
                              }}
                              color="black"
                              className="h-8 w-8 text-blue-600 cursor-pointer"
                            />
                          )}
                        </div>
                        <div className="md:col-span-6 mb-4 md:mb-0 xs:h-48 sm:h-92 lg:72">
                          <div className="text-center text-lg font-medium pb-2">
                            {p.name}
                          </div>
                          <div className="flex flex-col sm:flex-row justify-around gap-x-8">
                            <div className="flex flex-col w-full">
                              <div>
                                <span className="font-medium">
                                  {" "}
                                  {t("Technologies")}:
                                </span>{" "}
                                {p.technologies.join(", ")}
                              </div>
                              <div>
                                <span className="font-medium">
                                  {" "}
                                  {t("Customer")}:
                                </span>{" "}
                                {p.customer}
                              </div>
                              <div>
                                <span className="font-medium">
                                  {t("Year")}:
                                </span>{" "}
                                {p.year}
                              </div>
                              {p.link && (
                                <div>
                                  <span className="font-medium">
                                    {" "}
                                    {t("Link")}:
                                  </span>{" "}
                                  <a
                                    className="text-blue-500 hover:text-blue-400"
                                    href={p.link}
                                    target="__blank"
                                  >
                                    {p.link}
                                  </a>
                                </div>
                              )}
                            </div>
                            <div className="w-full">{t(p.description)}</div>
                          </div>
                        </div>

                        <div className="block flex justify-between md:hidden">
                          {i !== 0 && (
                            <ArrowLeftIcon
                              onClick={() => {
                                setItem((prev) => {
                                  return prev - 1;
                                });
                              }}
                              className="h-8 w-8 text-blue-600 cursor-pointer"
                            />
                          )}
                          {i === 0 && <div></div>}
                          {i !== projects.length - 1 && (
                            <ArrowRightIcon
                              className="h-8 w-8 text-blue-600 cursor-pointer"
                              onClick={() => {
                                setItem((prev) => {
                                  return prev + 1;
                                });
                              }}
                            />
                          )}
                        </div>
                        <div className="hidden md:flex justify-center">
                          {i !== projects.length - 1 && (
                            <ArrowRightIcon
                              className="h-8 w-8 text-blue-600 cursor-pointer"
                              onClick={() => {
                                setItem((prev) => {
                                  return prev + 1;
                                });
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
    </PageWrapper>
  );
};

export default Home;
