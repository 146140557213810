import React from "react";
import { useTranslation } from "react-i18next";
import PageWrapper from "./PageWrapper";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper title={"Contact"}>
      <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-4 sm:gap-y-0 gap-y-4">
        <div className="w-full">
          <iframe
            className="w-full"
            title="H"
            height="350"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            src="https://www.openstreetmap.org/export/embed.html?bbox=7.890530526638032%2C46.684135896409096%2C7.894398272037507%2C46.6857716748311&amp;layer=mapnik&amp;marker=46.684953791811594%2C7.8924643993377686"
          ></iframe>
        </div>{" "}
        <small className="flex sm:hidden justify-center">
          <a href="https://www.openstreetmap.org/?mlat=46.68495&amp;mlon=7.89246#map=19/46.68495/7.89246">
            {t("larger_map")}
          </a>
        </small>
        <div className="flex items-center justify-center h-full bg-gray-300 py-8">
          <div className="text-lg text-center text-black">
            <div>Swiss Tech Solutions GmbH</div>
            <div>Hauptstrasse 26</div>
            <div>3806 Bönigen b. Interlaken</div>
            <div>Switzerland</div>
            <div>
              <a
                className="text-blue-500 hover:text-blue-400"
                href="mailto:info@swisstechsolutions.ch"
              >
                info@swisstechsolutions.ch
              </a>
            </div>
          </div>
        </div>
        <small className="hidden sm:block">
          <a href="https://www.openstreetmap.org/?mlat=46.68495&amp;mlon=7.89246#map=19/46.68495/7.89246">
            {t("larger_map")}
          </a>
        </small>
      </div>
    </PageWrapper>
  );
};

export default Contact;
