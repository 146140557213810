import React from "react";
import { useTranslation } from "react-i18next";
import PageWrapper from "./PageWrapper";

const REMO = require("../assets/img/team/jpeg/remo.jpeg");
const REMO_WEBP = require("../assets/img/team/webp/remo.webp");

const About = () => {
  const { t } = useTranslation();

  const team = [
    {
      firstname: "Remo",
      lastname: "Röthlisberger",
      bio: "remo_bio",
      IMAGE: REMO,
      WEBP_IMAGE: REMO_WEBP,
    },
  ];

  return (
    <PageWrapper title={"About"}>
      <div className="p-4 space-y-4">
        <div className="space-y-3">
          <div className="text-xl font-semibold border-b border-b">
            {t("About")}
          </div>
          <div className="bg-gray-300 text-black p-4 rounded-2xl">
            {t("about_paragraph")}
          </div>
        </div>
        <div>
          <div className="space-y-3">
            <div className="text-xl font-semibold border-b border-b">
              {t("Team")}
            </div>
            <div className="grid gird-cols-1 gap-y-4">
              {team.map((p) => {
                return (
                  <div className="grid grid-cols-3 items-center bg-gray-300 text-black p-4 rounded-2xl gap-x-4">
                    <div className="col-span-3 text-lg font-semibold">
                      {p.firstname} {p.lastname}
                    </div>
                    <div className="flex justify-center">
                      <picture>
                        <source srcSet={p.WEBP_IMAGE} />
                        <img
                          className="rounded-full w-24"
                          alt={p.firstname + " " + p.lastname}
                          src={p.IMAGE}
                        />
                      </picture>
                    </div>
                    <div className="col-span-2">{t(p.bio)}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default About;
