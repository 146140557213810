import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import Navigation from "../components/Navigation";

import Footer from "../components/Footer";

const PageWrapper = ({ children, title }) => {
  const { pathname, search, hash, state, key } = useLocation();
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Swiss Tech Solutions GmbH | {title}</title>
        <link rel="canonical" href={process.env.PUBLIC_URL + "/#" + pathname} />
      </Helmet>
      <div className="flex flex-col w-screen h-screen bg-gray-500">
        <div>
          <Navigation currentpath={pathname} />
          <div className="flex-grow max-w-5xl mx-auto sm:py-12 px-0 pb-8 sm:px-2 text-white">
            {children}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default PageWrapper;
