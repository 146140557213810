import { useState } from "react";
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LOGO_PNG = require("../assets/img/logos/png/Logo_invert.png");
const LOGO_WEBP = require("../assets/img/logos/webp/Logo_invert.webp");

export default function Navigation({ currentpath }) {
  const { t, i18n } = useTranslation();

  const [textBlack, setTextBlack] = useState(false);

  const sitemap = [
    {
      name: "Home",
      link: "/",
    },
    { name: "About", link: "/about" },
    { name: "Contact", link: "/contact" },
  ];

  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="flex-shrink-0 flex items-center">
                  <Link to={"/"}>
                    <picture>
                      <source srcSet={LOGO_WEBP} />
                      <img
                        className="block h-12 w-auto"
                        src={LOGO_PNG}
                        alt="Workflow"
                      />
                    </picture>
                  </Link>
                </div>

                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                  {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                  {sitemap.map((p) => {
                    return (
                      <Link
                        to={p.link}
                        className={`${
                          currentpath === p.link
                            ? "border-white text-white"
                            : "border-transparent text-gray-300 hover:text-gray-200 hover:border-gray-300"
                        } inline-flex items-center px-1 pt-1 border-b-2 text-md font-medium`}
                      >
                        {t(p.name)}
                      </Link>
                    );
                  })}
                </div>
              </div>
              <div className="my-auto hidden sm:block">
                <div className="flex text-white gap-x-5 mr-5">
                  <div
                    className={`cursor-pointer ${
                      i18n.language === "en" ? "font-bold" : ""
                    }`}
                    onClick={() => {
                      i18n.changeLanguage("en");
                    }}
                  >
                    {t("English")}
                  </div>
                  |
                  <div
                    className={`cursor-pointer ${
                      i18n.language === "de" ? "font-bold" : ""
                    }`}
                    onClick={() => {
                      i18n.changeLanguage("de");
                    }}
                  >
                    {t("German")}
                  </div>
                </div>
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                <div className="flex text-white text-sm gap-x-5 mr-5">
                  <div
                    className={`cursor-pointer ${
                      i18n.language === "en" ? "font-bold" : ""
                    }`}
                    onClick={() => {
                      i18n.changeLanguage("en");
                    }}
                  >
                    {t("English")}
                  </div>
                  |
                  <div
                    className={`cursor-pointer ${
                      i18n.language === "de" ? "font-bold" : ""
                    }`}
                    onClick={() => {
                      i18n.changeLanguage("de");
                    }}
                  >
                    {t("German")}
                  </div>
                </div>
                {/* Mobile menu button */}
                <Disclosure.Button
                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-300 hover:text-gray-200 hover:bg-gray-300"
                  onMouseOver={() => {
                    setTextBlack(true);
                  }}
                  onMouseLeave={() => {
                    setTextBlack(false);
                  }}
                >
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon
                      className={`block h-6 w-6 hover:text-black ${
                        textBlack ? "text-black" : ""
                      }`}
                      aria-hidden="true"
                    />
                  ) : (
                    <MenuIcon
                      className={`block h-6 w-6 hover:text-black ${
                        textBlack ? "text-black" : ""
                      }`}
                      aria-hidden="true"
                    />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="sm:hidden">
            <div className="pt-2 pb-3 space-y-1">
              {sitemap.map((p) => {
                return (
                  <Link to={p.link}>
                    <Disclosure.Button
                      as="a"
                      className={`
                    ${
                      currentpath === p.link
                        ? "border-white text-white"
                        : "border-transparent text-gray-300 hover:text-gray-200 hover:border-gray-300"
                    }
                    block pl-3 pr-4 py-2 border-l-4 text-base font-medium`}
                    >
                      {t(p.name)}
                    </Disclosure.Button>
                  </Link>
                );
              })}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
