import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = () => {
  const date = new Date();
  const { i18n } = useTranslation();

  return (
    <div className="fixed bottom-0 z-50 w-screen">
      <div className="flex justify-center bg-gray-200">
        <div className="flex space-x-2"></div>
        <Link
          className="text-blue-500 hover:text-blue-400 px-2"
          to={"/contact"}
        >
          Swiss Tech Solutions GmbH
        </Link>{" "}
        © {Intl.DateTimeFormat("en-US", { year: "numeric" }).format(date)}
      </div>
    </div>
  );
};

export default Footer;
